import { graphql } from 'gatsby'
import React from 'react'

import { IndexQueryQuery } from '../../../types/graphql-types'
import Meta from '../../components/meta/meta'
import Layout from '../../components/layout/layout'

interface Props {
  data: IndexQueryQuery
  location: Location
}

const NewDomainsOpening: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta

  return (
    <Layout edition="01-2021" location={location}>
      <Meta site={meta} />
      <div className="content-inner shadow-lg">
        <div
          className="page-header"
          style={{
            backgroundImage: 'url(../../img/lon-new-domains-header.jpg)',
          }}
        ></div>
        <article className="post">
          <h1>New e-commerce and domains opening</h1>
          <p>
            We will open a few new Longines domains to extend and reinforce our
            local presence in some countries such as:
          </p>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Country</th>
                  <th>Website url</th>
                  <th>Language</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <span className="flag-icon flag-icon-ca mr-2"></span>
                    Canada
                  </td>
                  <td>
                    www.longines.com/en-ca
                    <br />
                    www.longines.com/fr-ca
                  </td>
                  <td>
                    English
                    <br />
                    French
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="flag-icon flag-icon-gr mr-2"></span>
                    Greece
                  </td>
                  <td>www.longines.com/gr-gr</td>
                  <td>Greek</td>
                </tr>
                <tr>
                  <td>
                    <span className="flag-icon flag-icon-in mr-2"></span>
                    India
                  </td>
                  <td>www.longines.com/en-in</td>
                  <td>English</td>
                </tr>
                <tr>
                  <td>
                    <span className="flag-icon flag-icon-mo mr-2"></span>
                    Macao
                  </td>
                  <td>www.longines.com/zh-mo</td>
                  <td>Traditional Chinese</td>
                </tr>
                <tr>
                  <td>
                    <span className="flag-icon flag-icon-my mr-2"></span>
                    Malaysia
                  </td>
                  <td>www.longines.com/en-my</td>
                  <td>English</td>
                </tr>
                <tr>
                  <td>
                    <span className="flag-icon flag-icon-tw mr-2"></span>
                    Taiwan
                  </td>
                  <td>www.longines.com/zh-tw</td>
                  <td>Traditional Chinese - Taiwan</td>
                </tr>
                <tr>
                  <td>
                    <span className="flag-icon flag-icon-sg mr-2"></span>
                    Singapore
                  </td>
                  <td>www.longines.com/en-sg</td>
                  <td>English</td>
                </tr>
                <tr>
                  <td>
                    <span className="flag-icon flag-icon-za mr-2"></span>
                    South Africa
                  </td>
                  <td>www.longines.com/en-za</td>
                  <td>English</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="spacer"></div>

          <div className="row align-items-center justify-content-center">
            <div className="col-md-8">
              <div className="alert alert-light text-primary shadow">
                <div className="d-flex align-items-center">
                  <ion-icon
                    name="rocket-outline"
                    style={{ marginRight: '30px', fontSize: '36px' }}
                  ></ion-icon>
                  <div>
                    <h3>Go live ?</h3>
                    <p className="mb-0">April and May 2021</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="spacer"></div>
          <hr />
          <div className="spacer"></div>
          <p>
            In 2021, Longines will speed-up the rollout of its corporate
            e-stores. Each portal will enable the end consumer to purchase our
            products directly online and to be delivered at the address of
            her/his choice.
          </p>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Country</th>
                  <th>Website url</th>
                  <th>Language</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <span className="flag-icon flag-icon-pl mr-2"></span>
                    Poland
                  </td>
                  <td>www.longines.com/pl</td>
                  <td>Polish</td>
                </tr>
                <tr>
                  <td>
                    <span className="flag-icon flag-icon-nl mr-2"></span>
                    Netherlands
                  </td>
                  <td>
                    www.longines.com/nl-nl
                    <br />
                    www.longines.com/en-nl
                  </td>
                  <td>
                    Dutch
                    <br />
                    English
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="flag-icon flag-icon-se mr-2"></span>
                    Sweden
                  </td>
                  <td>www.longines.com/en-se</td>
                  <td>English</td>
                </tr>
                <tr>
                  <td>
                    <span className="flag-icon flag-icon-es mr-2"></span>
                    Spain
                  </td>
                  <td>www.longines.com/es</td>
                  <td>Spanish</td>
                </tr>
                <tr>
                  <td>
                    <span className="flag-icon flag-icon-at mr-2"></span>
                    Austria
                  </td>
                  <td>www.longines.com/de-at</td>
                  <td>German</td>
                </tr>
                <tr>
                  <td>
                    <span className="flag-icon flag-icon-be mr-2"></span>
                    Belgium
                  </td>
                  <td>
                    www.longines.com/fr-be
                    <br />
                    www.longines.com/nl-be
                  </td>
                  <td>
                    French
                    <br />
                    Dutch
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="flag-icon flag-icon-dk mr-2"></span>
                    Denmark
                  </td>
                  <td>www.longines.com/en-dk</td>
                  <td>English</td>
                </tr>
                <tr>
                  <td>
                    <span className="flag-icon flag-icon-fi mr-2"></span>
                    Finland
                  </td>
                  <td>www.longines.com/en-fi</td>
                  <td>English</td>
                </tr>
                <tr>
                  <td>
                    <span className="flag-icon flag-icon-no mr-2"></span>
                    Norway
                  </td>
                  <td>www.longines.com/en-no</td>
                  <td>English</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="spacer"></div>

          <div className="row align-items-center justify-content-center">
            <div className="col-md-8">
              <div className="alert alert-light text-primary shadow">
                <div className="d-flex align-items-center">
                  <ion-icon
                    name="rocket-outline"
                    style={{ marginRight: '30px', fontSize: '36px' }}
                  ></ion-icon>
                  <div>
                    <h3>Go live ?</h3>
                    <p className="mb-0">April 2021</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="spacer"></div>
          <blockquote>
            <div className="d-flex">
              <img
                src={'../../img/blaise-virchaux.jpg'}
                className="rounded-circle shadow-lg mr-3"
                style={{ width: '80px', height: '80px' }}
              />
              <div>
                <h4>Need further information?</h4>
                <p>
                  Please email{' '}
                  <a href="mailto:blaise.virchaux@longines.com">
                    blaise.virchaux@longines.com
                  </a>{' '}
                  and he will be glad to answer your questions and organize a
                  Skype call at your convenience.
                </p>
              </div>
            </div>
          </blockquote>
        </article>
      </div>
    </Layout>
  )
}

export default NewDomainsOpening

export const pageQuery = graphql`
  query NewDomainsOpeningQuery_01_2021 {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
      }
    }
  }
`
